<template lang="pug">
  b-form-group
    label
      | 郵便番号
      b-badge(class="ml-1" pill variant="danger") 必須
    b-input-group
      b-input(
        :name="name"
        type="tel"
        placeholder="７桁数字"
        maxlength="7"
        v-model="localValue"
      )
      b-input-group-append
        a(href="#" class="btn btn-outline-info" v-if="!loading" @click.prevent="onClick") 郵便番号検索
        a(href="#" class="btn btn-outline-secondary" v-if="loading") ロード中


</template>

<script>
import axios from "axios";

const API_URL = "/api/zip_code";

export default {
  props: {
    name: {
      type: String,
      default: "zip"
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    onClick() {
      const val = this.value.replace(/[^0-9]/g, "");
      if (val.length !== 7) return;
      const config = {params: {zipcode: val}};
      this.loading = true;
      axios
        .get(API_URL, config)
        .then(e => {
          if (e.data && e.data.results && e.data.results[0]) {
            this.$emit("address", e.data.results[0]);
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.zip {
  width: 200px;
}
</style>
