<template lang="pug">
  .text-center
    b-button.py-3(block size="lg" :variant="variant" @click="$emit('click')" :disabled="disabled")
      i.fas.mr-1(:class="icon")
      | {{ text }}
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: "pending"
    }
  },
  data() {
    return {};
  },
  computed: {
    icon() {
      switch (this.status) {
        case "pending":
          return "fa-shopping-cart";
        case "sending":
          return "fa-spinner fa-spin";
        case "success":
          return "fa-check-circle";
      }
      return "";
    },
    variant() {
      switch (this.status) {
        case "pending":
          return "primary";
        case "sending":
          return "secondary";
        case "success":
          return "success";
      }
      return "";
    },
    text() {
      switch (this.status) {
        case "pending":
          return "カートへ反映する";
        case "sending":
          return "反映中...";
        case "success":
          return "反映完了";
      }
      return "";
    },
    disabled() {
      return this.status === "sending";
    }
  }
};
</script>
