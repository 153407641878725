<template>
  <div>
    <div v-if="!requesting">
      <a :class="badgeClass" href="#" @click.prevent="onClick">{{ badgeLabel }}</a>
    </div>
    <div v-if="!requesting && downloadAtInner" >
      <small><a href="#" @click="onClickRegenerateLink">再生成</a></small>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserEventOrderZipDownloadLink",
  props: {
    orderId: {
      type: Number,
    },
    href: {
      type: String
    },
    downloadAt: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      downloadAtInner: this.downloadAt,
      requesting: false,
    }
  },
  computed: {
    badgeClass() {
      if (this.downloadAtInner) {
        return 'badge badge-secondary'
      }
      return 'badge badge-primary'
    },
    badgeLabel() {
      if (this.downloadAtInner) {
        return 'DL済'
      }
      return '未DL'
    }
  },
  methods: {
    onClick() {
      if (!this.downloadAtInner) {
        window.open(this.href)
        this.downloadAtInner = Date.now().toString()
      }
    },
    async onClickRegenerateLink() {
      this.requesting = true
      await window.axios.post('/api/order/create_zip', { 'order_id': this.orderId })
      this.requesting = false
      this.downloadAtInner = null
      window.alert('Zipファイルの生成が完了しました')
    }
  }
}
</script>

<style scoped>

</style>
