<template lang="pug">
  div
    a(href="#" @click="$emit('back-to-list')")
      i.fa.fa-angle-left.mr-2
      | 一覧へ戻る

    h2.mt-3 {{ photo.name }}

    .d-flex.mb-1
      store-photo-prev-button.mr-auto
      store-photo-next-button.ml-auto

    b-row
      b-col
        .watermarked
          b-img.w-100.no-copy(:src="photo.image" thumbnail)

          // 描画高速化のための先読み
          .d-none(v-if="$store.getters.nextPhoto")
            img(:src="$store.getters.nextPhoto.image")
          .d-none(v-if="$store.getters.prevPhoto")
            img(:src="$store.getters.prevPhoto.image")

    .d-flex.mt-1
      store-photo-prev-button.mr-auto
      store-photo-next-button.ml-auto

    h4.mt-4 サイズ選択
    b-row.mb-5
      b-col(cols="6" md="4" lg="3" v-for="variation in variations" :key="`${photo.id}-${variation.id}`")
        store-variation-spin-button.mt-2(
          :photo="photo"
          :variation="variation"

          @change="onChangeVariationAmount"
        )

</template>

<script>
import _ from "lodash";
import StoreVariationSpinButton from "./StoreVariationSpinButton";
import StorePhotoNextButton from "./StorePhotoNextButton";
import StorePhotoPrevButton from "./StorePhotoPrevButton";

export default {
  name: "StorePhotoDetail",
  components: {
    StorePhotoPrevButton,
    StorePhotoNextButton,
    StoreVariationSpinButton
  },
  props: {
    photo: {
      required: true,
      type: Object
    },
    variations: {
      required: true,
      type: Array
    }
  },
  methods: {
    onChangeVariationAmount(variationId, qty) {
      const payload = { photoId: this.photo.id, variationId, qty };
      this.$store.dispatch("updateCartItem", payload);
    }
  }
};
</script>

<style scoped></style>
