<template lang="pug">
  div
    .text-center.mb-5 合計: {{ value }} 円 (税抜)
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
};
</script>
