<template lang="pug">
  div
    store-photo-list(
      v-if="!isCurrentPhoto"
      :photos="photos"
      @select="onSelectPhoto"
    )

    store-photo-detail(
      v-if="isCurrentPhoto"
      :photo="$store.getters.currentPhoto"
      :variations="$store.getters.currentVariations"
      @back-to-list="$store.commit('unsetPhotoIndex')"
    )
</template>

<script>
import _ from "lodash";
import store from "./StoreEventVuex";
import StorePhotoList from "./StorePhotoList";
import StoreCartBtn from "./StoreCartBtn";
import StorePhoto from "./StorePhotoDetail";

export default {
  name: "StoreEvent",
  components: {
    StorePhoto,
    StoreCartBtn,
    StorePhotoList
  },
  props: {
    event: {
      required: true,
      type: Object
    },
    photos: {
      required: true,
      type: Array
    },
    variationGroups: {
      required: true,
      type: Array
    },
    cartItems: {
      required: true,
      tpe: Array
    }
  },
  data() {
    return {};
  },
  methods: {
    onSelectPhoto(index) {
      this.$store.commit("setPhotoIndex", index);
    },
    getPhotoIndexByUrl() {
      const hash = window.location.hash;
      if (hash.length > 0) {
        const id = Number(hash.substr(1));
        return _.findIndex(this.photos, { id });
      }
      return -1;
    }
  },
  computed: {
    isCurrentPhoto() {
      return this.$store.getters.currentPhoto !== null;
    }
  },
  created() {
    this.$store.commit("init", {
      event: this.event,
      photos: this.photos,
      variationGroups: this.variationGroups,
      cartItems: this.cartItems
    });
    const photoIndex = this.getPhotoIndexByUrl();
    if (photoIndex >= 0) {
      this.$store.commit("setPhotoIndex", photoIndex);
    }
  },
  store
};
</script>

<style scoped></style>
