<template lang="pug">
  b-row
    b-col.mb-4(cols="6" md="4" lg="3" v-for="(photo, index) in photos" :key="photo.id")
      .text-black-50 {{ photo.name }}
      a.img-link.img-thumbnail.watermarked(:href="photoUrl(photo)" @click="onClick(index)")
        b-img.img(:src="photo.thumb")
</template>

<style scoped>
.img-link {
  display: block;
  height: 150px;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.watermarked:after {
  background-image: none !important;
}
</style>

<script>
export default {
  name: "StorePhotoList",
  props: {
    photos: {
      required: true,
      type: Array
    }
  },
  methods: {
    photoUrl(photo) {
      return `#${photo.id}`;
    },
    onClick(photo) {
      this.$emit("select", photo);
    }
  }
};
</script>
