<template>
  <b-input-group>
    <b-form-input ref="input" :value="value"></b-form-input>
    <b-input-group-append>
      <b-button variant="outline-primary" @click="onCopy">コピー</b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    onCopy: function(e) {
      this.$refs.input.select();
      document.execCommand("copy");
    }
  }
};
</script>
