<template>
  <p>Hoge</p>
</template>

<script>
export default {
  name: "Hoge"
}
</script>

<style scoped>

</style>
