<template lang="pug">
  div
    .d-flex
      b.mr-auto {{ variation.name }}
      span.ml-auto {{ variation.unit_price }} 円
    b-form-spinbutton(min="0" v-model="value")

</template>

<script>
export default {
  name: "StoreVariationSpinButton",
  props: {
    photo: {
      required: true,
      type: Object
    },
    variation: {
      required: true,
      type: Object
    }
  },
  data() {
    const index = _.findIndex(this.$store.state.cartItems, {
      photoId: this.photo.id,
      variationId: this.variation.id
    });
    const value = index >= 0 ? this.$store.state.cartItems[index].qty : 0;
    return { value };
  },
  watch: {
    value(newValue, oldValue) {
      this.$emit("change", this.variation.id, newValue);
    }
  }
};
</script>
