<template lang="pug">
  .d-flex.flex-wrap.justify-content-around
    span(v-for="preset in presets").mr-2
      a(href="#" @click="onClick(preset)") {{ preset.name }}
</template>

<script>
  export default {
    name: "UserVariationPresetButton",
    data() {
      return {
        presets: [
          {name: 'L', unit_price: ''},
          {name: 'LP', unit_price: ''},
          {name: '2L', unit_price: ''},
          {name: 'MLBD', unit_price: ''},
          {name: 'MMBD', unit_price: ''},
          {name: 'ML', unit_price: ''},
          {name: 'LM', unit_price: ''},
          {name: 'KGM', unit_price: ''},
          {name: 'LBD', unit_price: ''},
          {name: 'HV', unit_price: ''},
          {name: 'P', unit_price: ''},
          {name: 'A5', unit_price: ''},
          {name: 'DSC', unit_price: ''},
          {name: 'DSCW', unit_price: ''},
          {name: 'A5S', unit_price: ''},
          {name: 'LSQ', unit_price: ''},
          {name: 'LSQB', unit_price: ''},
          {name: 'S152', unit_price: ''},
        ],
      }
    },
    methods: {
      onClick(preset) {
        this.$emit('select', preset.name, preset.name, preset.unit_price)
      }
    }
  }
</script>
