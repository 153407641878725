<template>
  <a :class="badgeClass" href="#" @click.prevent="onClick">{{ badgeLabel }}</a>
</template>

<script>
export default {
  name: "UserEventOrderPaidAtLink",
  props: {
    orderId: {
      type: String,
      required: true,
    },
    paidAt: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      innerPaidAt: this.paidAt
    }
  },
  computed: {
    badgeLabel() {
      return this.innerPaidAt ? '完了' : '未納';
    },
    badgeClass() {
      if (this.innerPaidAt) {
        return 'badge badge-secondary'
      }
      return 'badge badge-danger'
    }
  },
  methods: {
    onClick() {
      const yes = window.confirm("支払い状況を変更しますか？")
      if (!yes) {
        return
      }
      const url = '/api/order/toggle_paid_at'
      const data = { order_id: this.orderId }
      window.axios.post(url, data).then((res) => {
        if (res.status === 200 && res.data.result) {
          this.innerPaidAt = res.data.paidAt
        }
      }).catch((error) => {
        alert("エラー: " + error.message())
      })
    }
  }
}
</script>

<style scoped>

</style>
