<template lang="pug">
  b-list-group
    b-list-group-item(
      v-for="(photoGroup, index) in photoGroups" :key="index"
      :class="{ active: isActive(photoGroup) }"
      :href="href(photoGroup)")
      | {{ photoGroup.name }}
</template>

<script>
export default {
  name: "StorePhotoGroupList",
  props: {
    photoGroups: {
      required: true,
      type: Array
    },
    currentPhotoGroup: {
      required: true,
      type: Object
    }
  },
  methods: {
    href(photoGroup) {
      return `?photo_group_id=${photoGroup.id}`;
    },
    isActive(photoGroup) {
      return photoGroup.id === this.currentPhotoGroup.id;
    }
  }
};
</script>
