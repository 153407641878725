<template lang="pug">
  b-modal(v-model="modal" size="xl" title="Modal" @hidden="cartBtnState = 'pending'")
    template(v-slot:modal-title) {{ photo.name }}

    .row
      .col-lg-7.d-flex.justify-content-center
        .watermarked
          b-img(thumbnail fluid :src="photo.thumb_large_url")
      .col-lg-5
        h3 用紙サイズ
        .row
          .col-6.form-group(v-for="variation in photo.variations" :key="variation.id")
            .d-flex.justify-content-between
              div
                i.fas.fa-caret-right
                b {{ variation.name }}
              div {{ variation.unit_price }} 円
            .input-group
              .input-group-prepend
                b-btn(@click="decrement(variation)")
                  i.fas.fa-minus
              b-form-input.text-right(v-model="variation.qty" @change="cartBtnState = 'pending'")
              .input-group-append
                b-btn(@click="increment(variation)")
                  i.fas.fa-plus
        .row
          .col
            .text-right.border-bottom.mb-3 小計： {{ subtotal }} 円
            photo-modal-cart-btn(@click="onClickCartBtn(photo)" :status="cartBtnState")

    template(v-slot:modal-footer)
      b-btn(size="lg" @click="$emit('previous')") << 前の写真へ
      b-btn(size="lg" @click="$emit('next')") 次の写真へ >>
</template>

<script>
import axios from "axios";

export default {
  props: {
    route: String,
    photo: Object
  },
  data() {
    return {
      modal: false,
      cartBtnState: "pending"
    };
  },
  computed: {
    subtotal() {
      let sum = 0;
      this.photo.variations.forEach(function(v) {
        if (v.qty > 0) {
          sum += v.qty * v.unit_price;
        }
      });
      return sum;
    }
  },
  methods: {
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    increment(variation) {
      variation.qty += 1;
      this.cartBtnState = "pending";
    },
    decrement(variation) {
      variation.qty = variation.qty > 0 ? variation.qty - 1 : 0;
      this.cartBtnState = "pending";
    },
    onClickCartBtn(photo) {
      if (this.cartBtnState === "success") {
        this.cartBtnState = "pending";
        return;
      }
      this.cartBtnState = "sending";
      axios
        .post(this.route, photo)
        .then(e => {
          this.cartBtnState = "success";
          this.$emit("cart-update", e.data);
        })
        .catch(function(e) {
          window.alert(
            "通信に失敗しました。しばらく待ってから再度お試しください。"
          );
        });
    }
  }
};
</script>
