<template>
  <form method="POST" :action="to">
    <input type="hidden" name="_token" :value="token" />
    <input type="hidden" name="_method" value="DELETE" />
    <button type="submit" class="btn btn-danger" @click="onClick">
      削除する
    </button>
  </form>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    confirm: {
      type: String,
      default: "本当に削除しますか？"
    }
  },
  data() {
    return {
      token: document.querySelector('meta[name="csrf-token"]').content
    };
  },
  methods: {
    onClick(e) {
      if (!window.confirm(this.confirm)) {
        e.preventDefault();
      }
    }
  }
};
</script>
