/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from "vue";
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue";
import vue2Dropzone from "vue2-dropzone";
// import CKEditor from "@ckeditor/ckeditor5-vue";

Vue.use(Vuex);
Vue.use(BootstrapVue);
// Vue.use(CKEditor);
Vue.component("VueDropzone", vue2Dropzone);

import "./bootstrap";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

// Vue.component('photo-modal', require('./components/PhotoModal').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(function() {
  if (!window.App) {
    let defaultOption = { el: "#app" };
    window.AppOption = window.AppOption
      ? Object.assign(defaultOption, window.AppOption)
      : defaultOption;
    window.App = new Vue(window.AppOption);
  }

  // 削除ボタンには確認を挟む
  $(".confirm-delete").on("click", function(e) {
    const msg = "この操作は取り消すことができません。\n本当に削除しますか？";
    if (!confirm(msg)) {
      e.preventDefault();
    }
  });
});
