<template lang="pug">
  a(v-if="isPrev" href="#" @click.prevent="onClick")
    i.fas.fa-angle-left.mr-2
    | 前へ

</template>

<script>
export default {
  name: "StorePhotoPrevButton",
  computed: {
    isPrev() {
      return this.$store.getters.prevPhoto != null;
    }
  },
  methods: {
    onClick() {
      // hashを変更してからstateを変えないと、hashが2つ先を示すようになってしまう
      window.location.hash = this.$store.getters.prevPhoto.id;
      this.$store.commit("prevPhoto");
    }
  }
};
</script>
