<template>
  <b-button @click.prevent="onClick">自動入力</b-button>
</template>

<script>
export default {
  name: "AutoFillButton",
  methods: {
    onClick() {
      document.getElementsByName('name_sei')[0].value = "山田"
      document.getElementsByName('name_mei')[0].value = "太郎"
      document.getElementsByName('zip')[0].value = "1234567"
      document.getElementsByName('prefecture')[0].value = "東京都"
      document.getElementsByName('city')[0].value = "新宿区"
      document.getElementsByName('address1')[0].value = "新宿"
      document.getElementsByName('address2')[0].value = "1-1"
      document.getElementsByName('email')[0].value = "test@test.com"
      document.getElementsByName('tel')[0].value = "09012345678"
      document.getElementsByName('message')[0].value = "test@test.com"
    }
  }
}
</script>

<style scoped>

</style>
