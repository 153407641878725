<template lang="pug">
  b-btn.mb-2(block variant="success" size="lg" :href="to")
    i.fas.fa-shopping-cart.mr-1
    | カートをみる
</template>

<script>
export default {
  name: "StoreCartBtn",
  props: {
    to: {
      required: true,
      type: String
    }
  }
};
</script>
