<template lang="pug">
  a(v-if="isNext" href="#" @click.prevent="onClick")
    | 次へ
    i.fas.fa-angle-right.ml-2

</template>

<script>
export default {
  name: "StorePhotoNextButton",
  computed: {
    isNext() {
      return this.$store.getters.nextPhoto != null;
    }
  },
  methods: {
    onClick() {
      // hashを変更してからstateを変えないと、hashが2つ先を示すようになってしまう
      window.location.hash = this.$store.getters.nextPhoto.id;
      this.$store.commit("nextPhoto");
    }
  }
};
</script>
