import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import axios from "axios";

export default new Vuex.Store({
  state: {
    userId: null,
    eventId: null,
    photoGroups: [],
    photos: [],
    photoIndex: -1,
    variationGroups: [],
    cartItems: []
  },
  getters: {
    currentPhoto(state) {
      return state.photoIndex >= 0 && state.photos[state.photoIndex]
        ? state.photos[state.photoIndex]
        : null;
    },
    nextPhoto(state) {
      return state.photoIndex >= 0 && state.photos[state.photoIndex + 1]
        ? state.photos[state.photoIndex + 1]
        : null;
    },
    prevPhoto(state) {
      return state.photoIndex > 0 && state.photos[state.photoIndex - 1]
        ? state.photos[state.photoIndex - 1]
        : null;
    },
    currentVariations(state) {
      if (state.photoIndex >= 0 && state.photoIndex < state.photos.length) {
        const variationGroup = _.find(state.variationGroups, {
          id: state.photos[state.photoIndex].variation_group_id
        });
        if (variationGroup) {
          return variationGroup.variations;
        }
      }
      return [];
    }
  },
  actions: {
    updateCartItem({ commit, state }, { photoId, variationId, qty }) {
      commit("updateCartItemRow", { photoId, variationId, qty });

      // サーバ側へ変更を通知
      const items = _.filter(state.cartItems, { photoId });
      const params = {
        id: photoId,
        variations: _.map(items, item => {
          return { id: item.variationId, qty: item.qty };
        })
      };
      axios
        .post(`/store/${state.userId}/event/${state.eventId}/cart`, params)
        .then(res => {
          console.log(res);
        });
    }
  },
  mutations: {
    init(state, args) {
      state.userId = args.event.user_id;
      state.eventId = args.event.id;
      state.photos = args.photos;
      state.variationGroups = args.variationGroups;
      state.cartItems = args.cartItems;
    },
    setPhotoIndex(state, index) {
      if (state.photos[index] !== undefined) {
        state.photoIndex = index;
      }
    },
    unsetPhotoIndex(state) {
      state.photoIndex = -1;
    },
    nextPhoto(state) {
      if (state.photoIndex >= 0 && state.photos[state.photoIndex + 1]) {
        state.photoIndex++;
      }
    },
    prevPhoto(state) {
      if (state.photoIndex > 0 && state.photos[state.photoIndex - 1]) {
        state.photoIndex--;
      }
    },
    updateCartItemRow(state, { photoId, variationId, qty }) {
      const index = _.findIndex(state.cartItems, { photoId, variationId });
      const item = { photoId, variationId, qty };
      if (index < 0) {
        // cartItemsに存在しない場合は追加
        state.cartItems.push(item);
      } else if (qty <= 0) {
        // 値が0の場合はcartItemsから削除
        state.cartItems.splice(index, 1);
      } else {
        // その他は上書き
        Vue.set(state.cartItems, index, item);
      }
    }
  }
});
